import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const predefined = Loadable({
  loader: () => import("./components/predefined"),
  loading: Loading
});

const customreports = Loadable({
  loader: () => import("./components/customreports"),
  loading: Loading
});

export const routes = [
  
  {
    path: "/reports/predefined",
    exact: true,
    component: predefined,
    name: "Predefined Reports"
  },
  {
    path: "/reports/customreports",
    exact: true,
    component: customreports,
    name: "Reports"
  }

  

];
