import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";


const travel_request = Loadable({
  loader: () => import("./components/travel_request"),
  loading: Loading
});
const payment_request = Loadable({
  loader: () => import("./components/payment_request"),
  loading: Loading
});
const purchase_order = Loadable({
  loader: () => import("./components/purchase_order"),
  loading: Loading
});
const purchase_requisition = Loadable({
  loader: () => import("./components/purchase_requisition"),
  loading: Loading
});
const invoice_posting = Loadable({
  loader: () => import("./components/invoice_posting"),
  loading: Loading
});
const invoice_acq_ret = Loadable({
  loader: () => import("./components/invoice_acq_ret"),
  loading: Loading
});
const monthly_reporting = Loadable({
  loader: () => import("./components/monthly_reporting"),
  loading: Loading
});
const budget_planning = Loadable({
  loader: () => import("./components/budget_planning"),
  loading: Loading
});
const achievement = Loadable({
  loader: () => import("./components/achievement"),
  loading: Loading
});
const payment_desk = Loadable({
  loader: () => import("./components/payment_desk"),
  loading: Loading
});
const datasheet = Loadable({
  loader: () => import("./components/datasheet"),
  loading: Loading
});
const monthly_reporting_correction = Loadable({
  loader: () => import("./components/monthly_reporting_correction"),
  loading: Loading
});

const financeimport = Loadable({
  loader: () => import("./components/financeimport"),
  loading: Loading
});
const proposal_submission = Loadable({
  loader: () => import("./components/proposal_submission"),
  loading: Loading
});
const wai_reporting_planner = Loadable({
  loader: () => import("./components/wai_reporting_planner"),
  loading: Loading
});
const water_quality_sample = Loadable({
  loader: () => import("./components/water_quality_sample"),
  loading: Loading
});
const water_quality_report = Loadable({
  loader: () => import("./components/water_quality_report"),
  loading: Loading
});
const monthly_reporting_approval = Loadable({
  loader: () => import("./components/monthly_reporting_approval"),
  loading: Loading
});
const importplan = Loadable({
  loader: () => import("./components/importplan"),
  loading: Loading
});
const importfinancedata = Loadable({
  loader: () => import("./components/importfinancedata"),
  loading: Loading
});
export const routes = [
  
  {
    path: "/dataentry/travel_request",
    exact: true,
    component: travel_request,
    name: "Travel Request"
  },
  {
    path: "/dataentry/payment_request",
    exact: true,
    component: payment_request,
    name: "Payment Request"
  },
  {
    path: "/dataentry/purchase_order",
    exact: true,
    component: purchase_order,
    name: "Purchase Order"
  },
  {
    path: "/dataentry/purchase_requisition",
    exact: true,
    component: purchase_requisition,
    name: "Purchase Requisition"
  },
  {
    path: "/dataentry/invoice_posting",
    exact: true,
    component: invoice_posting,
    name: "Invoice Posting"
  },
  {
    path: "/dataentry/invoice_acq_ret",
    exact: true,
    component: invoice_acq_ret,
    name: "Invoice Acquisition/Retention"
  },
  {
    path: "/dataentry/monthly_reporting",
    exact: true,
    component: monthly_reporting,
    name: "Monthly Reporting"
  },
  {
    path: "/dataentry/budget_planning",
    exact: true,
    component: budget_planning,
    name: "Budget Planning"
  },
  {
    path: "/dataentry/achievement",
    exact: true,
    component: achievement,
    name: "Achievement"
  },
  {
    path: "/dataentry/payment_desk",
    exact: true,
    component: payment_desk,
    name: "Payment Desk"
  },
  {
    path: "/dataentry/datasheet",
    exact: true,
    component: datasheet,
    name: "datasheet"
  },
  {
    path: "/dataentry/monthly_reporting_correction",
    exact: true,
    component: monthly_reporting_correction,
    name: "Monthly Data Correction "
  },
  {
    path: "/dataentry/financeimport",
    exact: true,
    component: financeimport,
    name: "Import Finance "
  },
  {
    path: "/dataentry/proposal_submission",
    exact: true,
    component: proposal_submission,
    name: "Proposal Submission"
  },
  {
    path: "/dataentry/wai_reporting_planner",
    exact: true,
    component: wai_reporting_planner,
    name: "Wai Reporting Planner"
  },
  {
    path: "/dataentry/water_quality_sample",
    exact: true,
    component: water_quality_sample,
    name: "Sample Info"
  },
  {
    path: "/dataentry/water_quality_report",
    exact: true,
    component: water_quality_report,
    name: "Water Quality Report"
  },
  {
    path: "/dataentry/monthly_reporting_approval",
    exact: true,
    component: monthly_reporting_approval,
    name: "Monthly Data Approval"
  },
  {
    path: "/dataentry/importplan",
    exact: true,
    component: importplan,
    name: "Import Plan"
  },
  {
    path: "/dataentry/importfinancedata",
    exact: true,
    component: importfinancedata,
    name: "Import Finance Data"
  },

];
